<template>
  <div data-test="select-project">
    <b-field v-if="is_dropdown" :label="label">
      <b-autocomplete
        ref="autocomplete"
        v-model="project_name"
        :append-to-body="append_to_body"
        clearable
        :data="filteredProjects"
        data-test="select-project-input"
        field="attributes.name"
        iconRight="circle-xmark"
        open-on-focus
        @select="selectProject"
        @update:modelValue="searchProject"
      >
        <template #empty>
          <div data-test="empty-result">
            <div v-if="is_searching">
              {{ $t('select.actions.noResults') }} <span v-if="project_name">"{{ project_name }}"</span>
            </div>
            <div v-else>
              {{ $t('select.actions.startTyping') }}
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>
    <b-input
      v-else
      v-model="search_project"
      :placeholder="$t('project.labels.searchProject')"
      @update:modelValue="searchProject"/>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useToasts } from '@/app/util/toasts';
import _ from 'lodash-es';

const toast_action_failed = useToasts().toast_action_failed;

const props = defineProps({
  active: { type: Object, default: null },
  append_to_body: { type: Boolean, default: false },
  label: { type: String, default: null },
  projects_props: { type: Object, default: null },
  is_dropdown: { type: Boolean, default: true },
});

const emit = defineEmits(['on-search', 'change-select']);

const store = useStore();
const project_name = ref(props.active ? props.active.attributes.name : '');
const is_searching = ref(false);
const projects_search = ref({});

const projects = computed(() => {
  if (props.projects_props) {
    return props.projects_props;
  }
  return projects_search.value;
});

const filteredProjects = computed(() => _.filter(projects.value,
  (p) => p.attributes.name.toLowerCase().indexOf(project_name.value.toLowerCase()) >= 0));

watch(() => props.active, (newVal) => {
  project_name.value = _.get(newVal, 'attributes.name', '');
});

const autocompleteProjects = _.debounce(async (search = '') => {
  try {
    projects_search.value = _.keyBy(
      await store.dispatch('AUTOCOMPLETE_PROJECTS', {
        name_cont: search
      }), 'id');
  } catch (error) {
    toast_action_failed()
  }
}, 100);

const searchProject = (query) => {
  if (query.length >= 1) {
    is_searching.value = true;
    autocompleteProjects(query);
  } else {
    is_searching.value = false;
  }
  emit('onSearch', query);
};

const selectProject = (project) => {
  emit('changeSelect', project);
};
</script>
