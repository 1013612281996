<template>
  <nav v-if="!$_.isNil(current_project)"
       class="navbar-sm-project navbar"
       data-test="navbar-customer-tablet-sidenavbar">
    <div class="is-flex is-align-items-center mx-4">
      <div
        class="is-flex-grow-1 is-flex is-align-items-center">
        <a><FontAwesomeIcon icon="bars" size="lg"
                            @click="show_mobile_navigation = !show_mobile_navigation"/></a>
        <breadcrumbs class="is-size-5 ml-4 " :is_medium_size="false"/>
      </div>
      <NotificationSidebar/>
      <ProfileDropdown/>
    </div>

    <Drawer
      :pt="{
        content: 'pt-0 px-5 pb-5 overflow-y-auto'
      }"
      :visible="show_mobile_navigation"
      @update:visible="show_mobile_navigation = false">
      <div v-if="$_.values(projects).length > 1" class="select mb-5 ml-1">
        <b-field>
          <b-select
            :modelValue="current_project.id"
            rounded
            @update:modelValue="p_id => initializeProject(p_id)">
            <option
              v-for="project in projects"
              :key="project.id"
              :value="project.id">
              {{ project.attributes.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div v-else class="title has-text-primary is-4 ziggu-navbar-item ml-4" style="margin-bottom: 2.5rem;">
        {{ current_project.attributes.name }}
      </div>
      <div
        v-for="(route, idx) in routesActive" :key="idx"
        class="mb-5">
        <router-link
          :data-test="route.data_test"
          :to="{ name: route.name, params: { project_id: current_project.id } }">
          <div
            class="is-flex is-direction-row is-align-items-center"
            @click="show_mobile_navigation = false">
            <div class="is-size-5 ml-4">
              {{ $t(route.title) }}
            </div>
          </div>
        </router-link>
      </div>
    </Drawer>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationSidebar from '@/app/shared_components/navbar/notifications/TheNotificationsSidebar.vue';
import ProfileDropdown from '@/app/shared_components/navbar/TheProfileDropdown.vue';
import { NAVBAR_ITEMS_CUSTOMER } from '@/app/data/navbar_constants';
import { ROUTES_CUSTOMERS } from '@/app/data/route_constants';

export default {
  name: 'NavbarTablet',
  components: { NotificationSidebar, ProfileDropdown },

  data() {
    return {
      NAVBAR_ITEMS_CUSTOMER,
      show_mobile_navigation: false,
    };
  },

  computed: {
    ...mapGetters([
      'current_client',
      'current_project',
      'projects',
    ]),
    routesActive() { return _.filter(NAVBAR_ITEMS_CUSTOMER, this.isRouteActive); },
  },

  methods: {
    initializeProject(project_id) {
      this.$router.push({
        name: ROUTES_CUSTOMERS.DASHBOARD,
        params: { project_id, client_id: this.current_client.id },
      });
    },

    isRouteActive(route) {
      if (route.flipper) {
        return this.$flipper.enabled(route.flipper);
      }
      return true;
    },
  },
};
</script>
