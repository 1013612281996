// import external libraries

import { createApp } from 'vue'

// import css stylesheets
import '@/assets/css/index.css'
import '@/assets/css/style.css'
import '@/assets/css/vue-popper.css';
import '@/assets/images/fontawesome.js';

// noinspection ES6UnusedImports
import _ from 'lodash-es';
import Buefy from '@ntohq/buefy-next';
import * as Sentry from '@sentry/vue';
import { Integrations as TracingIntegration } from '@sentry/tracing';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { Vue3Mq } from 'vue3-mq';
import AutoRotate from 'vue-jpeg-auto-rotation';

// import PrimeVue components
import PrimeVue from 'primevue/config';
import Lara from '@primevue/themes/lara';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Galleria from 'primevue/galleria'; // Marketplace Modal
import DataTable from 'primevue/datatable'; // Supervisor Dashboard
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TreeSelect from 'primevue/treeselect';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Ripple from 'primevue/ripple';
import PAvatar from 'primevue/avatar';
import InputNumber from 'primevue/inputnumber';
import PickList from 'primevue/picklist';
import Drawer from 'primevue/drawer';
import ConfirmDialog from 'primevue/confirmdialog';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Popover from 'primevue/popover';
import RadioButton from 'primevue/radiobutton';
import CascadeSelect from 'primevue/cascadeselect';
import MultiSelect from 'primevue/multiselect';
import Divider from 'primevue/divider';
import Tooltip from 'primevue/tooltip';
import ConfirmationService from 'primevue/confirmationservice';

import VueConfetti from 'vue-confetti';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// import internal Libraries
// noinspection ES6UnusedImports

import api from './api'; // eslint-disable-line no-unused-vars
import App from './App.vue';
import eventBus from '@/plugins/event_bus';
import breadcrumbs from '@/plugins/breadcrumbs/breadcrumbs';
import KeepScrollPosition from '@/plugins/navigation/keep_scroll_position';
import Avatar from '@/plugins/avatar/Avatar.vue';
import FormatCurrency from '@/app/shared_components/currency/FormatCurrency.vue';
import GlobalConfirmModal from '@/app/shared_components/modals/GlobalConfirmModal.vue';
import buttons from '@/app/shared_components/buttons/buttons.js';
import i18n from '@/i18n/index';
import router from '@/router/index';
import { Auth0Plugin } from '@/auth';
import store from '@/store/index';
import toasts from '@/app/util/toasts';
import sequentialPromise from '@/app/util/synchronous_promise';
import VPopper from '@/app/shared_components/tooltips/VPopper.vue';
import TitleSmall from '@/app/shared_components/titles/TitleSmall.vue';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import truncate from '@/plugins/truncate';

// import constants
import { ZIGGU_API_URL } from '@/app/util/urls';
import flipper from '@/plugins/flipper';
import { FT } from '@/app/data/flipper_constants';

const app = createApp(App)

app.use({
  install() {
    app.config.globalProperties.emitter = eventBus;
  },
});

app.use(store);
app.use(router);
app.use(i18n);

app.use(Buefy, { defaultIconPack: 'fas' });

// Initialize Sentry
if (!import.meta.env.IS_LOCAL) {
  Sentry.init({
    app,
    dsn: 'https://be16e9a319304d88b103e2300a4f669f@sentry.io/1190623',
    release: `${import.meta.env.RELEASE_VERSION}`,
    environment: `${import.meta.env.VITE_ENV}`,
    ignoreErrors: [
      'TypeError: NetworkError when attempting to fetch resource.',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop completed with undelivered notifications.',
      // Ignore MutationObserver error from vue3-popper
      'Failed to execute \'observe\' on \'MutationObserver\': parameter 1 is not of type \'Node\'.',
      // https://github.com/nico3333fr/CSP-useful/blob/master/csp-wtf/explained.md#apple-quicklook
      'Refused to evaluate a string as JavaScript because \'unsafe-eval\' is not '
      + 'an allowed source of script in the following Content Security Policy directive: '
      + '"default-src x-apple-ql-id: \'unsafe-inline\'"',
      // expired Google Cloud Storage URLs for PDFs
      'Unexpected server response (400) while retrieving PDF',
      // PDF-related errors
      'InvalidPDFException',
      /Invalid PDF structure/i,
      /UnknownErrorException: Load failed/i,
      // Network-related errors
      /^Network Error$/i,
      /Failed to fetch/i,
      /NetworkError when attempting to fetch resource/i,
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new DedupeIntegration(),
      new TracingIntegration.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [`${ZIGGU_API_URL.replace('https://', '')}`],
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.25, // percentage
    normalizeDepth: 8,
    // prevent 401 error logs (expired sessions, see api/index.js) being sent to Sentry
    beforeSend(event, hint) {
      const isUnauthorizedError = hint
        && hint.originalException
        && hint.originalException.response
        && hint.originalException.response.status
        // 401: user unauthorized (legitimate in general)
        // 403: resource unauthorized (legitimate in general, e.g. stale data due to cache)
        && _.includes([401, 403], hint.originalException.response.status);
      const isSentryDiscard = hint
        && hint.originalException
        && hint.originalException.sentry_discard
        && hint.originalException.sentry_discard === true;
      const isNonErrorException = hint
        && hint.originalException
        && hint.originalException.error
        && event
        && event.message
        && event.message.startsWith('Non-Error exception captured');
      const isAPIError = hint
        && hint.originalException
        && hint.originalException.response
        && hint.originalException.response.status
        // 500: API raised an unexpected error, Sentry will log this on API-side, logging of APP side error is redundant
        && hint.originalException.response.status === 500;
      const isChunkError = (event.message && event.message.startsWith('Loading chunk '))
        || (event.name && event.name === 'ChunkLoadError');
      // Filter out errors only when www appears before a subdomain (e.g., www.subdomain.domain.com)
      const hostnameParts = window.location.hostname.split('.');
      const isWWWSubdomain = hostnameParts.length > 2 && hostnameParts[0] === 'www';
      // ignore network errors in general, typically noise, mostly due to Safari https://github.com/axios/axios/issues/4420
      const isNetworkError = (event.name && event.name === 'AxiosError' && event.message && event.message === 'Network Error')
        || (hint && hint.originalException && hint.originalException.message && (
          hint.originalException.message === 'Network Error'
          || hint.originalException.message.includes('Failed to fetch')
          || hint.originalException.message.includes('NetworkError')
        ));

      if (isUnauthorizedError || isSentryDiscard || isChunkError || isNetworkError || isAPIError || isWWWSubdomain) {
        return null;
      }

      // Errors from Auth0 extends original Error class and cause issues with Sentry
      if (isNonErrorException) {
        Sentry.withScope((scope) => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }

      return event;
    },
  });
} else {
  app.config.performance = true;
}

app.use(Auth0Plugin, {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  client_id: import.meta.env.VITE_AUTH0_APP_CLIENT_ID,
  audience: import.meta.env.VITE_AUTH0_API_AUDIENCE,
  onRedirectCallback: (appState) => {
    const path = _.get(appState, 'targetUrl', window.location.pathname);
    const query = _.get(appState, 'params', {});

    router.push({ path, query });
  },
});

app.use({
  install() {
    window._ = _;
    app.config.globalProperties.window = window;
    app.config.globalProperties.$_ = _;
    app.config.globalProperties.$FT = FT;
  }
});
app.config.globalProperties.sequentialPromise = sequentialPromise;

app.use(VueConfetti);
app.mixin(toasts);

app.component('Avatar', Avatar);
app.component('FormatCurrency', FormatCurrency);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('GlobalConfirmModal', GlobalConfirmModal);
app.component('ProgressSpinner', ProgressSpinner);
app.component('FontAwesomeLayers', FontAwesomeLayers);
app.component('Column', Column);
app.component('Modal', Modal);
app.component('VPopper', VPopper);
app.component('TitleSmall', TitleSmall);
app.component('Popover', Popover);
app.component('Dropdown', Dropdown);
app.component('Message', Message);
app.component('Galleria', Galleria);
app.component('DataTable', DataTable);
app.component('PrimeDialog', Dialog);
app.component('PickList', PickList);
app.component('TreeSelect', TreeSelect);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Toast', Toast);
app.component('InputNumber', InputNumber);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('RadioButton', RadioButton);
app.component('Drawer', Drawer);
app.component('PButton', Button);
app.component('PMenu', Menu);
app.component('PAvatar', PAvatar);
app.component('CascadeSelect', CascadeSelect);
app.component('MultiSelect', MultiSelect);
app.component('Divider', Divider);

app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);

app.use(PrimeVue, {
  // Default theme configuration
  theme: {
    preset: Lara,
    options: {
      darkModeSelector: false || 'none',
    }
  }
});

app.use(AutoRotate);
app.use(breadcrumbs);
app.use(flipper, store);
app.use(KeepScrollPosition);
app.use(buttons);
app.use(Vue3Mq);
app.use(ToastService);
app.use(ConfirmationService);

const truncatePlugin = {
  install () {
    app.truncate = truncate
    app.config.globalProperties.$truncate = truncate
  }
}
app.use(truncatePlugin)

// load error handling for active sessions (silent reload the page)
// https://vite.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  window.location.reload();
});

app.mount('#app');
