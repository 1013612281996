<template>
  <div
    class="font-medium w-full flex items-center cursor-pointer px-4 py-1 h-9 mb-2
    rounded-md text-surface-700 dark:text-surface-0/80 hover:bg-surface-100
    dark:hover:bg-surface-700 duration-200 transition-colors"
    data-test="project-item"
    @click="navigateTo"
    @mouseenter.stop="isHovering = true"
    @mouseleave.stop="isHovering = false">
    <div class="text-surface-700 dark:text-surface-0/80 grow truncate is-flex is-align-items-center gap-2">
      <span v-if="project.attributes.skip_customer" class="template-icon-container-small flex-shrink-0">
        <FontAwesomeIcon
          class="has-text-grey"
          :icon="['far', 'memo']"
          size="md"/>
      </span>

      <avatar v-else class="flex-shrink-0" size="25" :src="projectAvatarUrl" :username="projectAvatarName"/>

      <div class="ml-1 flex-shrink-1 truncate">
        {{ project.attributes.name }}
      </div>

      <div v-if="!project.attributes.skip_customer && !project.attributes.activated" class="tag ml-2 is-rounded flex-shrink-0">
        {{ $t('project.labels.deactivated') }}
      </div>
    </div>

    <PButton v-show="isHovering" class="flex-shrink-0" rounded severity="secondary"
             style="padding: .5rem !important;" text @click.stop="toggle" >
      <FontAwesomeIcon icon="ellipsis"/>
    </PButton>

    <PMenu id="overlay_menu" ref="menu" :model="overlayActions" :popup="true" >
      <template #item="{ item, props }">
        <a v-ripple class="flex align-items-center" v-bind="props.action" @click="item.action">
          {{ item.label }}
        </a>
      </template>
    </Pmenu>
  </div>

  <b-modal v-model="is_delete_modal">
    <Modal
      :is_confirmed="project.attributes.name.toLowerCase() === level_name.toLowerCase()"
      :is_small="false"
      @actionPrimary="deleteProject">
      <template #title>
        {{ $t('modal.labels.removeTitle', { record_name: project.attributes.name }) }}?
      </template>
      <template #notification_danger>
        <div class="is-flex is-align-items-center">
          <div class="icon mr-2">
            <FontAwesomeIcon icon="triangle-exclamation" size="2x"/>
          </div>
          <span>{{ $t('levels.labels.deleteWarning') }}</span>
        </div>
      </template>
      <template #content>
        <div class="field" :data-delete-string="project.attributes.name" data-test="delete-input-confirm">
          <label class="label"
                 v-html="$t('decision_type.labels.writeEmployeeNameReject',
                            { employeeName: project.attributes.name })"/>
          <input v-model.trim="level_name" class="input" data-test="input-delete-confirm" type="text">
        </div>
      </template>
      <template #ButtonDestructive>
        {{ $t('levels.actions.delete') }}
      </template>
    </Modal>
  </b-modal>
</template>

<script>
import { Assignment, PROFILE_TYPES } from '@/app/data/model_constants';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import { FT } from '@/app/data/flipper_constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SidebarProjectItem',
  props: {
    project: { type: Object, required: true },
    is_following_project: { type: Boolean },
    assignments: { type: Object, default: null },
    is_template: { type: Boolean },
    toggled_project_id: { type: Number, default: null }
  },

  emits: ['toggleProjectId', 'unfollow', 'follow', 'closeProjectSidebar'],

  data() {
    return {
      isHovering: false,
      is_delete_modal: false,
      level_name: '',
    };
  },

  computed: {
    ...mapGetters(['current_profile', 'current_project']),

    assignment() {
      const attributes = {
        assigned_id: this.project.id,
        assigned_type: this.project.type,
        assignee_id: this.current_profile.id,
        assignee_type: this.current_profile.type,
        assignment_type: Assignment.TYPE_FOLLOW,
      };
      const assignments = _.filter(this.assignments, { attributes });
      return assignments[0] || null;
    },
    overlayActions() {
      const actions = [];
      if (!this.is_template) {
        actions.push({
          label: this.is_following_project
            ? this.$t('project.actions.unFollow') : this.$t('project.actions.follow'),
          action: this.toggleFollow,
        })
        if (this.project.attributes.rundle) {
          actions.push({
            label: this.project.attributes.activated ? this.$t('project.actions.deactivate') : this.$t('project.actions.activate'),
            action: this.toggleActivation,
          });
        }
      } else {
        actions.push({
          label: this.$t('levels.actions.delete'),
          action: this.toggleDeletion,
        })
      }
      return actions;
    },

    projectAvatarUrl() {
      if (this.project.attributes.skip_unit) {
        return this.project.attributes.customer_profile?.avatar;
      }
      return this.project.attributes.picture_login_url_s;
    },

    projectAvatarName() {
      if (this.project.attributes.skip_unit) {
        return this.project.attributes.customer_profile?.name;
      }
      return this.project.attributes.name;
    }
  },

  watch: {
    toggled_project_id() {
      if (this.toggled_project_id !== this.project.id) {
        this.$refs.menu.hide();
      }
    }
  },

  methods: {
    ...mapActions(['UPDATE_NON_CURRENT_PROJECT', 'DELETE_PROJECT']),
    toggleFollow() {
      if (this.assignment) {
        this.$emit('unfollow', this.assignment);
      } else {
        const assignment = {
          attributes: {
            assigned_id: this.project.id,
            assigned_type: this.project.type,
            assignee_id: this.current_profile.id,
            assignee_type: this.current_profile.type,
            project_id: this.project.id,
            assignment_type: Assignment.TYPE_FOLLOW,
          },
        };
        if (this.current_profile.type === PROFILE_TYPES.PARTNER) {
          assignment.attributes.client_id = this.project.attributes.client_id;
        }
        this.$emit('follow', assignment);
      }
    },
    toggle(event) {
      this.$emit('toggleProjectId', this.project.id);
      this.$refs.menu.toggle(event);
    },
    async toggleActivation() {
      try {
        await this.UPDATE_NON_CURRENT_PROJECT({
          record: {
            id: this.project.id,
            attributes: { activated: !this.project.attributes.activated }
          },
          purge: false
        })
        const i18n = this.project.attributes.activated ? 'project.toasts.activateSuccess' : 'project.toasts.deactivateSuccess';
        this.toast_success(this.$t(i18n, { project_name: this.project.attributes.name }));
        if (this.current_project?.id === this.project.id) {
          this.$router.go(); // refresh page if updating current project
        }
      } catch (e) {
        this.toast_action_failed();
      }
    },

    navigateTo() {
      const { id, type } = this.project;
      this.$emit('closeProjectSidebar');

      const route_name = this.getRouteName();

      this.$router.push({
        name: route_name,
        params: {
          project_id: id,
          level_type: type,
          level_id: id,
        },
      });
    },

    getRouteName() {
      if (!this.project.attributes.skip_customer) {
        return ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS_INFORMATION;
      }

      const FEATURE_ROUTES = {
        [FT.MILESTONES]: ROUTES_EMPLOYEES.PROJECT_MILESTONES,
        [FT.TODOS]: ROUTES_EMPLOYEES.PROJECT_TODO_LISTS,
        [FT.DOCUMENTS]: ROUTES_EMPLOYEES.PROJECT_DOCUMENTS,
        [FT.DECISIONS_CORE]: ROUTES_EMPLOYEES.PROJECT_DECISIONS,
        [FT.ANNOUNCEMENTS]: ROUTES_EMPLOYEES.PROJECT_NEWS,
        [FT.SURVEYS]: ROUTES_EMPLOYEES.PROJECT_SURVEYS,
        [FT.FAQS]: ROUTES_EMPLOYEES.PROJECT_FAQS,
      };

      return Object.entries(FEATURE_ROUTES).find(
        ([feature]) => this.$flipper.enabled(feature)
      )?.[1] || '';
    },

    toggleDeletion() {
      this.is_delete_modal = true;
    },

    async deleteProject() {
      await this.DELETE_PROJECT({ record: this.project });
      this.$router.push({ name: ROUTES_EMPLOYEES.CLIENT_DASHBOARD });
    },
  }
}
</script>

<style scoped lang="scss">
  .template-icon-container {
    align-items: center;
    background-color: #E8E8E8;
    border-radius: 50%;
    display: inline-flex;
    height: 25px;
    justify-content: center;
    width: 25px;
  }
</style>
