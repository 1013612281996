<template>
  <component
    :is="type"
    class="button is-primary has-text-weight-semibold"
    :class="[size, outlined ? 'is-outlined': '' ]"
    :disabled="(is_disabled || is_loading || null)"
    :to="to"
    @click.stop="submit">
    <div v-if="iconFontawesome && !is_loading">
      <FontAwesomeIcon class="mr-2" :icon="iconFontawesome" />
    </div>
    <div v-if="is_loading" class="icon">
      <FontAwesomeIcon icon="spinner" pulse/>
    </div>
    <div>
      <slot v-if="is_loading" name="loadingText">
        {{ $t('button.actions.loading') }}
      </slot>
      <slot v-else/>
    </div>
  </component>
</template>

<script>

const actions_to_fontawesome_icon = Object.freeze({
  approve: 'check',
  create: 'plus',
  download: 'download',
  invitation: 'envelope',
  new: 'plus',
  move: 'shuffle',
  publish: ['far', 'eye'],
  save: 'floppy-disk',
  send: 'paper-plane',
  share: 'share',
  sign: ['far', 'signature'],
  unpublish: ['far', 'eye-slash'],
  update: 'save',
  upload: 'upload',
});

export default {
  name: 'ButtonPrimary',
  props: {
    action: {
      type: String,
      default: null,
      validator: (prop) => Object.keys(actions_to_fontawesome_icon).includes(prop),
    },
    is_disabled: { type: Boolean },
    is_loading: { type: Boolean },
    to: { type: Object, default: null },
    outlined: { type: Boolean },
    size: { type: String, default: 'is-normal' },
  },

  emits: ['on-click'],

  data() {
    return {
      iconFontawesome: null,
    };
  },

  computed: {
    type() {
      return this.to ? 'router-link' : 'button';
    },
  },

  created() {
    this.iconFontawesome = actions_to_fontawesome_icon[this.action];
  },

  methods: {
    submit() {
      if (this.is_loading || this.is_disabled) return;
      this.$emit('onClick');
    },
  },
};
</script>
