import { ROUTES_SUPERVISORS } from '@/app/data/route_constants';

const supervisorRoutes = [
  {
    name: ROUTES_SUPERVISORS.CLIENTS,
    path: 'clients',
    component: () => import('@/app/_supervisors/pages/client/ClientsPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.CLIENT_NEW,
    path: 'clients/new',
    component: () => import('@/app/_supervisors/pages/client/ClientNewPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.CLIENT,
    path: 'clients/:client_id',
    component: () => import('@/app/_supervisors/pages/client/ClientPage.vue'),
    props: true,
    children: [
      {
        name: ROUTES_SUPERVISORS.CLIENT_EDIT,
        path: 'edit',
        component: () => import('@/app/_supervisors/pages/client/ClientEditPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_METRICS,
        path: 'redash',
        props: true,
        component: () => import('@/app/_supervisors/pages/client/ClientMetricsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_EMPLOYEES,
        path: 'employees',
        component: () => import('@/app/_supervisors/pages/client/EmployeesPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_FINANCIALS,
        path: 'financials',
        component: () => import('@/app/_supervisors/pages/client/ClientFinancialsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_PROJECTS,
        path: 'projects',
        props: true,
        component: () => import('@/app/_supervisors/pages/client/ProjectsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_CUSTOMERS,
        path: 'customers',
        component: () => import('@/app/_supervisors/pages/client/CustomersPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_LEADS,
        path: 'leads',
        component: () => import('@/app/_supervisors/pages/client/LeadsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_INTEGRATION,
        path: 'integrations/:integration_id',
        component: () => import('@/app/_supervisors/pages/client/IntegrationPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_INTEGRATIONS,
        path: 'integrations',
        component: () => import('@/app/_supervisors/pages/client/IntegrationsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_PARTNERS,
        path: 'partners',
        component: () => import('@/app/_supervisors/pages/client/PartnersPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_PROJECT_NEW,
        path: 'projects/new',
        component: () => import('@/app/_supervisors/pages/client/ProjectNewPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_PROJECT_EDIT,
        path: 'projects/:project_id/edit',
        component: () => import('@/app/_supervisors/pages/client/ProjectEditPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.CLIENT_EMPLOYEE_NEW,
        path: 'employees/new',
        component: () => import('@/app/_supervisors/pages/client/EmployeeNewPage.vue'),
      },
    ],
  },
  {
    name: ROUTES_SUPERVISORS.DASHBOARD,
    path: 'dashboard',
    component: () => import('@/app/_supervisors/pages/DashboardPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.EMAILS,
    path: 'emails',
    component: () => import('@/app/_supervisors/pages/emails/EmailsPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.USERS_SEARCH,
    path: 'users/search',
    component: () => import('@/app/_supervisors/pages/user/UsersSearchPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.USER,
    path: 'users/:user_id?',
    component: () => import('@/app/_supervisors/pages/user/User.vue'),
    props: true,
    children: [
      {
        name: ROUTES_SUPERVISORS.USER_AUTH0_LOGS,
        path: 'auth0_logs',
        component: () => import('@/app/_supervisors/pages/user/UserAuth0LogsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_AUTH0_LOG,
        path: 'auth0_logs/:auth0_log_id',
        component: () => import('@/app/_supervisors/pages/user/UserAuth0LogPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_INFO,
        path: 'info',
        component: () => import('@/app/_supervisors/pages/user/UserInfoPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_EMAIL_EVENTS,
        path: 'emails',
        component: () => import('@/app/_supervisors/pages/user/UserEmailEventsPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_EMAIL_EVENT,
        path: 'email/:email_event_type/:email_event_id',
        component: () => import('@/app/_supervisors/pages/emails/EmailEventPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_PROFILE,
        path: 'profiles/:profile_type/:profile_id',
        component: () => import('@/app/_supervisors/pages/user/Profile.vue'),
        children: [
          {
            name: ROUTES_SUPERVISORS.USER_PROFILE_INFO,
            path: 'info',
            component: () => import('@/app/_supervisors/pages/user/UserProfileInfoPage.vue'),
          },
          {
            name: ROUTES_SUPERVISORS.USER_PROFILE_EMAIL_EVENTS,
            path: 'emails',
            component: () => import('@/app/_supervisors/pages/user/UserProfileEmailEventsPage.vue'),
          },
          {
            name: ROUTES_SUPERVISORS.USER_PROFILE_EMAIL_EVENT,
            path: 'email/:email_event_type/:email_event_id',
            component: () => import('@/app/_supervisors/pages/emails/EmailEventPage.vue'),
          },
          {
            name: ROUTES_SUPERVISORS.USER_PROFILE_SESSIONS,
            path: 'sessions',
            component: () => import('@/app/_supervisors/pages/user/UserProfileSessionsPage.vue'),
          },
          {
            name: ROUTES_SUPERVISORS.USER_PROFILE_TENANTS,
            path: 'tenants',
            component: () => import('@/app/_supervisors/pages/user/UserProfileTenantsPage.vue'),
          },
        ],
      },
      {
        name: ROUTES_SUPERVISORS.USER_PROFILES,
        path: 'profiles',
        component: () => import('@/app/_supervisors/pages/user/UserProfilesPage.vue'),
      },
      {
        name: ROUTES_SUPERVISORS.USER_SENTRY,
        path: 'sentry',
        component: () => import('@/app/_supervisors/pages/user/UserSentryPage.vue'),
      },
    ],
  },
  {
    name: ROUTES_SUPERVISORS.EMAIL_EVENT,
    path: 'email_events/:email_event_type/:email_event_id',
    component: () => import('@/app/_supervisors/pages/emails/EmailEventPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.AUDIT_LOG_DATAS_SEARCH,
    path: 'audit/log_datas',
    component: () => import('@/app/_supervisors/pages/audit/LogDatasSearchPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.METRICS,
    path: 'metrics',
    component: () => import('@/app/_supervisors/pages/metrics/MetricsPage.vue'),
  },
  {
    name: ROUTES_SUPERVISORS.AUDIT_LOG_DATA,
    path: 'audit/log_data/:record_type/:record_id',
    component: () => import('@/app/_supervisors/pages/audit/LogDataPage.vue'),
  },
];

export default supervisorRoutes;
