<template>
  <div
    v-if="notification"
    class="dropdown-item is-paddingless"
    :class="{ 'has-background-white-ter': $_.isNil(notification.attributes.read_at)
      && !is_dashboard, 'box': is_dashboard }"
    :data-notifiable-resource-id="data_notifiable_resource_id"
    :data-notifiable-resource-type="data_notifiable_resource_type"
    data-test="notification-item"
    style="padding: 0;">
    <router-link
      :to="routeTo"
      @click="() => { if (is_survey_notification) { initSurvey() } }">
      <article class="media p-4" style="cursor: pointer;">
        <figure class="media-left">
          <avatar
            v-if="notification.attributes.notifier_name"
            size="40"
            :src="notification.attributes.notifier_avatar_url"
            :username="notification.attributes.notifier_name"/>
          <div
            v-else
            class="flex-center has-background-primary has-text-white"
            style="height: 40px; width: 40px; border-radius: 50%;">
            <div>
              <i :class="activityIcon"/>
            </div>
          </div>
        </figure>
        <a style="width: 100%;">
          <div class="media-content" style="display:flex;">
            <b-icon
              v-if="notification.attributes.opened_at === null"
              class="has-text-primary mr-2" data-test="unread-notification" icon="circle"
              pack="fas"
              size="is-small"
              style="height: 100%;"/>
            <div class="content">
              <span v-if="notification.attributes.notifier_name" class="has-text-weight-bold pr-1">
                {{ $truncate(notification.attributes.notifier_name, 35) }}
              </span>
              <span v-if="notification_subgroup.attributes.notifiers_count > 1" class="pr-1">
                {{ $t('notification.other_users', notification_subgroup.attributes.notifiers_count - 1) }}
              </span>
              <span class="pr-1" data-test="notification-text" v-html="notification_text"/>
              <div class="has-text-grey is-size-7">
                <span>{{ created_at }}</span>
                <span
                  v-if="notification.attributes.project_id && project_name"
                  class="is-capitalized">
                  &bull;
                  {{ $truncate(project_name, 30) }}
                </span>
                <span
                  v-if="level_name"
                  class="is-capitalized">
                  &bull;
                  {{ $truncate(level_name, 30) }}
                </span>
              </div>
            </div>
          </div>
        </a>
        <div class="media-right">
          <div class="field is-grouped">
            <VPopper>
              <template #tip>
                {{ $t(`notification.actions.mark_as_${($_.isNil(notification.attributes.read_at)
                  ? 'read' : 'unread')}`) }}
              </template>
              <template #content>
                <a class="is-small is-pulled-right p-2"
                   :data-test="`toggle-notification-${$_.isNil(notification.attributes.read_at) ? 'read' : 'unread'}`"
                   style="margin-top: -0.5rem;"
                   @click.stop.prevent="changeReadStatus(notification_subgroup)">
                  <FontAwesomeIcon
                    v-if="$_.isNil(notification.attributes.read_at)"
                    class="mark-notification-read" :icon="['far', 'eye']" />
                  <FontAwesomeIcon v-else :icon="['far', 'eye-slash']"/>
                </a>
              </template>
            </VPopper>
            &nbsp;&nbsp;&nbsp;
            <VPopper>
              <template #tip>
                {{ $t('notification.actions.delete') }}
              </template>
              <template #content>
                <a class="delete is-small is-pulled-right"
                   data-test="delete-notification"
                   @click.stop.prevent="$emit('onDelete')"/>
              </template>
            </VPopper>
          </div>
        </div>
      </article>
    </router-link>
  </div>
</template>

<script>
import { PROFILE_TYPES, RECORD_TYPE } from '@/app/data/model_constants';
import activity_notification_item_mixin from '@/app/util/activity_notification_item_mixin';
import { ACTIVITIES_ICONS } from '@/app/data/activities_constants';
import { NOTIFICATIONS_CUSTOMERS } from '@/app/data/notifications_constants';
import {
  NOTIFICATIONS_TO_ROUTES,
  NOTIFICATION_GROUP_TO_ROUTE,
} from '@/app/data/notifications_routes';
import { mapActions, mapGetters } from 'vuex';

import { distanceInWordsToNowWithLocale } from '@/app/util/date_fns';

export default {
  name: 'Notification',
  mixins: [activity_notification_item_mixin],
  props: { is_dashboard: { type: Boolean, default: false } },
  data() {
    return {
      is_fetching: false,
      PROFILE_TYPES,
    };
  },
  computed: {
    ...mapGetters([
      'current_profile',
      'notifications',
    ]),
    notification() {
      return _.find(this.notifications, ['id', this.notification_subgroup.attributes.notification_id]);
    },

    data_notifiable_resource_id() {
      switch (this.notification.attributes.notifiable_type) {
      case RECORD_TYPE.DOCUMENT:
        return this.notification.attributes.parameters.attachment_id;
      case RECORD_TYPE.ANNOUNCEMENT_LEVEL:
        return this.notification.attributes.parameters.announcement_id;
      default:
        return this.notification.attributes.notifiable_id;
      }
    },

    data_notifiable_resource_type() {
      switch (this.notification.attributes.notifiable_type) {
      case RECORD_TYPE.DOCUMENT:
        return RECORD_TYPE.ATTACHMENT;
      case RECORD_TYPE.ANNOUNCEMENT_LEVEL:
        return RECORD_TYPE.ANNOUNCEMENT;
      default:
        return this.notification.attributes.notifiable_type;
      }
    },

    created_at() {
      const created_at = distanceInWordsToNowWithLocale(this.notification_subgroup.attributes.updated_at);
      return created_at.charAt(0).toUpperCase() + created_at.slice(1);
    },

    activityIcon() {
      if (!this.notification) return 'far fa-bell fa-lg';
      const { notifiable_type } = this.notification.attributes;
      return _.find(ACTIVITIES_ICONS, ['notifiable_type', notifiable_type]).iconClass;
    },

    notificationRoute() {
      return NOTIFICATIONS_TO_ROUTES[this.notification.attributes.key];
    },

    notificationGroupRoute() {
      return NOTIFICATION_GROUP_TO_ROUTE[this.notification.attributes.key];
    },

    is_survey_notification() {
      return this.notification.attributes.key === NOTIFICATIONS_CUSTOMERS.SURVEY_PUBLISHED;
    },

    is_survey_answered() {
      return this.current_profile.type === PROFILE_TYPES.CUSTOMER && this.notification.attributes.opened_at !== null;
    },

    level_name() {
      // only show level name if not a grouped notification or route identical between grouped and non-grouped
      if (this.notification.attributes.group_notification_count > 1
        && this.notificationRoute !== this.notificationGroupRoute) return null;
      if (this.project_sup) return null;
      return this.notification.attributes.level_name;
    },

    notification_text() {
      const { notifications_count, notifiers_count } = this.notification_subgroup.attributes;

      const notification_params = {
        notifiable_name: this.notification.attributes.notifiable_name,
        group_notification_count: notifications_count,
        group_name: this.notification.attributes.group_name,
        customer_group_name: this.notification.attributes.customer_group_name,
        level_name: _.get(this.notification, 'attributes.level_name', null),
      };

      if (_.get(this.notification, 'attributes.parameters.customer_status')) {
        if (this.notification.attributes.key.includes('customers.')) {
          notification_params.status = this.$t(`issue.attributes.statuses.${
            this.notification.attributes.parameters.customer_status
          }`);
        } else {
          notification_params.status = this.notification.attributes.parameters.status;
        }
      }

      if (_.get(this.notification, 'attributes.parameters.status')
          && !this.notification.attributes.key.includes('issue.')) {
        notification_params.status = this.$t(`installment.labels.statuses.${
          this.notification.attributes.parameters.status
        }`);
      }

      let choice;
      if (notifiers_count === 1 && notifications_count === 1) {
        choice = 0;
      } else if (notifiers_count === 1 && notifications_count > 1) {
        choice = 1;
      } else if (notifiers_count > 1 && notifications_count === 1) {
        choice = 2;
      } else {
        choice = 3;
      }

      let notification_text = this.$t(
        this.notification.attributes.key,
        notification_params,
        choice,
      );
      // fallback to prior choice if determined choice within current language isn't present (meaning it's identical)
      if (notification_text === '_') {
        notification_text = this.$t(
          this.notification.attributes.key,
          notification_params,
          choice - 1,
        );
      }
      return notification_text;
    },

    routeTo() {
      const name = this.notification_subgroup.attributes.notifications_count > 1
        ? this.notificationGroupRoute
        : this.notificationRoute;
      const params = _.omitBy({
        project_id: this.notification.attributes.project_id,
        level_id: this.notification.attributes.level_id,
        level_type: this.notification.attributes.level_type,
        ...this.notification.attributes.parameters,
      }, _.isNil);
      return name ? { name, params } : {};
    },
  },

  methods: {
    ...mapActions([
      'OPEN_NOTIFICATION_SUBGROUP',
      'UNOPEN_NOTIFICATION_SUBGROUP',
    ]),

    async changeReadStatus(notification_subgroup) {
      if (this.is_fetching) return;
      const read = _.get(notification_subgroup, 'attributes.read');
      try {
        this.is_fetching = true;
        const call = read ? this.UNOPEN_NOTIFICATION_SUBGROUP : this.OPEN_NOTIFICATION_SUBGROUP;
        await call({ notification_subgroup });
        this.$emit('onChangeReadStatus');
      } catch (e) {
        this.toast_action_failed();
      } finally {
        this.is_fetching = false;
      }
    },

    initSurvey() {
      if (this.is_survey_answered) {
        this.toast_success(this.$t('survey.labels.surveyAlreadyAnswered'));
      } else {
        const { notifiable_id } = this.notification.attributes;
        this.emitter.$emit('initSurvey', notifiable_id);
      }
    },
  },
};

</script>
