import breadcrumbs from './Breadcrumbs.vue';
import { ref, inject } from 'vue';

const BREADCRUMBS_SYMBOL = 'breadcrumbs';

class BreadCrumbs {
  crumbs = ref([]);

  add(arr) {
    this.crumbs.value = arr;
  }

  append(arr) {
    arr.forEach((crumb) => this.crumbs.value.push(crumb));
  }

  reset() {
    this.crumbs.value = [];
  }
}

// Create a composable for use in setup()
export function useBreadcrumbs() {
  return inject(BREADCRUMBS_SYMBOL);
}

export default {
  install(app) {
    const breadcrumbsInstance = new BreadCrumbs();
    // Keep globalProperties for Options API
    app.config.globalProperties.$breadcrumbs = breadcrumbsInstance;
    // Add provide/inject support for Composition API
    app.provide(BREADCRUMBS_SYMBOL, breadcrumbsInstance);
    app.component('Breadcrumbs', breadcrumbs);
  },
};
