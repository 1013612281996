import Customer from '@/app/_customers/Customer.vue';
import customerRoutes from '@/router/customer/routes-customer';
import Employee from '@/app/_employees/Employee.vue';
import employeeRoutes from '@/router/employee/routes-employee';
import partnerRoutes from '@/router/partner/routes-partner';
import Partner from '@/app/_partners/Partner.vue';
import ErrorNotFound from '@/app/_shared/pages/ErrorNotFound.vue';
import ErrorInternalServer from '@/app/_shared/pages/ErrorInternalServer.vue';
import NotificationsRedirect from '@/app/_shared/pages/NotificationsRedirect.vue';
import {
  ROUTE_COMMON, ROUTES_CUSTOMERS, ROUTES_EMPLOYEES, ROUTES_PARTNERS, ROUTES_SUPERVISORS, ROUTES_USERS,
} from '@/app/data/route_constants';
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index';
import Supervisor from '@/app/_supervisors/Supervisor.vue';
import supervisorRoutes from '@/router/supervisor/routes-supervisor';
import { authGuard } from '@/auth';
import User from '@/app/_users/User.vue';
import { PROFILE_TYPES, Tenant } from '@/app/data/model_constants';
import userRoutes from '@/router/user/routes-user';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: ROUTES_EMPLOYEES.ROOT,
      path: '/employee/:client_id',
      component: Employee,
      children: employeeRoutes,
      props: true,
    },
    {
      name: ROUTES_CUSTOMERS.ROOT,
      path: '/customer/:client_id',
      component: Customer,
      children: customerRoutes,
      props: true,
    },
    {
      name: ROUTES_PARTNERS.ROOT,
      path: '/partner/:client_id',
      component: Partner,
      children: partnerRoutes,
      props: true,
    },
    {
      name: ROUTES_SUPERVISORS.ROOT,
      path: '/supervisor/',
      component: Supervisor,
      children: supervisorRoutes,
    },
    {
      name: ROUTES_USERS.ROOT,
      path: '/user/',
      component: User,
      children: userRoutes,
    },
    {
      name: ROUTE_COMMON.ROOT,
      path: '',
    },
    {
      name: ROUTE_COMMON.NOTIFICATIONS_REDIRECT,
      path: '/notifications/:client_id/:profile_type/:profile_id/:notification_id',
      component: NotificationsRedirect,
    },
    {
      name: ROUTE_COMMON.ERROR_NOT_FOUND,
      path: '/404',
      component: ErrorNotFound,
    },
    {
      name: ROUTE_COMMON.ERROR_INTERNAL_SERVER,
      path: '/500',
      component: ErrorInternalServer,
    },
    {
      name: ROUTE_COMMON.LOGIN_RESET_REDIRECT,
      path: '/lo/reset',
    },
    {
      name: ROUTE_COMMON.NOT_FOUND,
      path: '/:catchAll(.*)*',
      redirect: '/404',
    },
  ],
});

// subdomain redirect links due to Microsoft flagging our generic login.ziggu.app links in mails as malicious
router.beforeEach((to, from, next) => {
  if (to.name === ROUTE_COMMON.LOGIN_RESET_REDIRECT) {
    window.location.href = `https://${import.meta.env.VITE_AUTH0_DOMAIN}${to.fullPath}`;
  }
  return next();
});

// add authentication to each route
router.beforeEach(authGuard);

// supervisor impersonations or test clients: append profile meta params (facilitates URL sharing internally)
router.beforeEach((to, from, next) => {
  const current_profile_true_type = _.get(store, 'getters.current_profile_true.type');
  const is_test = _.get(store, 'getters.current_clientsi.attributes.status') === Tenant.STATUS_TEST;
  const is_impersonation = _.get(store, 'getters.current_is_impersonation');
  const is_impersonation_supervisor = is_impersonation && current_profile_true_type === PROFILE_TYPES.SUPERVISOR;
  const query_profile_id = to.query._profile_id;
  const current_profile_id = _.get(store, 'getters.current_profile.id');
  const current_profile_type = _.get(store, 'getters.current_profile.type');

  if (!query_profile_id && current_profile_id && current_profile_type && (is_impersonation_supervisor || is_test)) {
    // eslint-disable-next-line no-param-reassign
    to.query._profile_id = current_profile_id;
    // eslint-disable-next-line no-param-reassign
    to.query._profile_type = current_profile_type;

    if (to.path !== from.path) {
      return next({ path: to.path, query: to.query, hash: to.hash });
    }
  }
  return next();
});

// scroll to top after change route
router.beforeEach((to, from, next) => {
  const page = document.getElementById('page');
  if (page && from.path !== to.path) page.scrollTop = 0;
  next();
});

export default router;
