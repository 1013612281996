import { VITE_ENV_REVIEW } from '@/app/data/general_constants';
import { PROFILE_TYPES, RECORD_TYPE, Tenant } from '@/app/data/model_constants';

function zigguSubdomainURL(subdomain) {
  // suffix consists of TLD and possibly port (if non-default)
  const suffix = window.location.origin.split('.').pop();
  // prefix only for review env based on merge request id
  const prefix = import.meta.env.VITE_ENV === VITE_ENV_REVIEW ? `${import.meta.env.VITE_PULL_REQUEST_VM_IDX}.` : '';
  return `https://${subdomain}.${prefix}ziggu.${suffix}`;
}

function zigguProfileTenantURL(profile, tenant) {
  let subdomain;
  if (profile.type === PROFILE_TYPES.SUPERVISOR) {
    subdomain = 'supervisor';
  } else if (tenant.attributes.tenantable_type === RECORD_TYPE.PROJECT) {
    subdomain = tenant.attributes.subdomain;
  } else if (tenant.attributes.tenantable_type === RECORD_TYPE.CLIENT) {
    if ([Tenant.STATUS_TRIAL, Tenant.STATUS_DEMO].includes(tenant.attributes.status)) {
      subdomain = profile.attributes.subdomain;
    } else {
      subdomain = tenant.attributes.subdomain;
    }
  }
  return zigguSubdomainURL(subdomain);
}

const ZIGGU_PARENT_URL = (() => {
  // suffix consists of TLD and possibly port (if non-default)
  const suffix = window.location.origin.split('.').pop();
  // prefix only for review env based on merge request id
  const prefix = import.meta.env.VITE_ENV === VITE_ENV_REVIEW ? `${import.meta.env.VITE_PULL_REQUEST_VM_IDX}.` : '';
  return `https://${prefix}ziggu.${suffix}`;
})();

const ZIGGU_API_PORT_LOCAL = 3000;

const ZIGGU_API_URL = (() => {
  const subdomain = 'api';
  // suffix consists of TLD and possibly port (if non-default, and override APP port with API port)
  let suffix = window.location.origin.split('.').pop();
  if (suffix.includes(':')) suffix = `${suffix.split(':')[0]}:${ZIGGU_API_PORT_LOCAL}`;
  // prefix only for review env based on merge request id
  const prefix = import.meta.env.VITE_ENV === VITE_ENV_REVIEW ? `${import.meta.env.VITE_PULL_REQUEST_VM_IDX}.` : '';
  return `https://${subdomain}.${prefix}ziggu.${suffix}`;
})();

export {
  ZIGGU_API_URL,
  ZIGGU_PARENT_URL,
  zigguSubdomainURL,
  zigguProfileTenantURL,
};
