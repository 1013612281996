// the constants must be a 1-1 mapping with the corresponding Ruby file

export const FT = {
  AFTERCARE: 'aftercare',
  AFTERCARE_CUSTOMER_FEEDBACK: 'aftercare_customer_feedback',
  AFTERCARE_CUSTOMER_ISSUE_CREATION: 'aftercare_customer_issue_creation',
  ANNOUNCEMENTS: 'announcements',
  CONVERSATION_MENTIONS: 'conversation_mentions',
  CUSTOMERS: 'customers',
  DECISIONS_CORE: 'decisions_core',
  DECISIONS_DOCUMENTS: 'decisions_documents',
  DECISIONS_FINANCIALS: 'decisions_financials',
  DECISIONS_FINANCIALS_EXTENDED: 'decisions_financials_extended',
  DECISIONS_PARTNERS: 'decisions_partners',
  DECISIONS_REMINDERS: 'decisions_reminders',
  DECISIONS_TICKETS: 'decisions_tickets',
  DOCUMENT_CATEGORIES: 'document_categories',
  DOCUMENTS: 'documents',
  EMAIL_FORWARDING_CLIENT: 'email_forwarding_client',
  EMAIL_FORWARDING_PROJECT: 'email_forwarding_project',
  EMPLOYEE_CUSTOM_THEME: 'employee_custom_theme',
  EMPLOYEE_IMPORT_CUSTOMERS: 'employee_import_customers',
  EMPLOYEE_IMPORT_SPACES: 'employee_import_spaces',
  EMPLOYEE_IMPORT_UNITS: 'employee_import_units',
  FAQS: 'faqs',
  FINANCIALS: 'financials',
  HORIZONTAL_NAV: 'horizontal_nav',
  INFORMATION_FULL: 'information_full',
  INSPIRATIONS: 'inspirations',
  MILESTONES: 'milestones',
  PROJECT_PARTNERS: 'project_partners',
  PROJECT_SUBDOMAINS: 'project_subdomains',
  PROPOSAL_CHOICE_LISTS: 'proposal_choice_lists',
  PROPOSAL_SUBCHOICES: 'proposal_subchoices',
  PROPOSAL_TEMPLATES: 'proposal_templates',
  SALES: 'sales',
  SALES_PARTNERS: 'sales_partners',
  SURVEYS: 'surveys',
  TEMPLATE_PROJECTS: 'template_projects',
  TICKET_CATEGORIES: 'ticket_categories',
  TICKETS: 'tickets',
  TODOS: 'todos',
}

export const FEATURES_CLIENT = [
  FT.AFTERCARE,
  FT.AFTERCARE_CUSTOMER_FEEDBACK,
  FT.AFTERCARE_CUSTOMER_ISSUE_CREATION,
  FT.CONVERSATION_MENTIONS,
  FT.CUSTOMERS,
  FT.DECISIONS_CORE,
  FT.DECISIONS_FINANCIALS_EXTENDED,
  FT.DECISIONS_PARTNERS,
  FT.DECISIONS_TICKETS,
  FT.DECISIONS_REMINDERS,
  FT.DECISIONS_DOCUMENTS,
  FT.DOCUMENT_CATEGORIES,
  FT.EMAIL_FORWARDING_CLIENT,
  FT.EMAIL_FORWARDING_PROJECT,
  FT.EMPLOYEE_IMPORT_CUSTOMERS,
  FT.EMPLOYEE_CUSTOM_THEME,
  FT.EMPLOYEE_IMPORT_SPACES,
  FT.EMPLOYEE_IMPORT_UNITS,
  FT.FINANCIALS,
  FT.INSPIRATIONS,
  FT.MILESTONES,
  FT.PROJECT_PARTNERS,
  FT.PROJECT_SUBDOMAINS,
  FT.PROPOSAL_SUBCHOICES,
  FT.PROPOSAL_TEMPLATES,
  FT.PROPOSAL_CHOICE_LISTS,
  FT.DECISIONS_FINANCIALS,
  FT.SALES,
  FT.TEMPLATE_PROJECTS,
  FT.TICKET_CATEGORIES,
  FT.ANNOUNCEMENTS,
  FT.SURVEYS,
  FT.FAQS,
  FT.TODOS,
  FT.TICKETS,
  FT.INFORMATION_FULL,
  FT.DOCUMENTS,
]

export const FEATURES_EMPLOYEE = [
  FT.HORIZONTAL_NAV,
]

export const FEATURES_CORE = [
  FT.TICKETS,
  FT.DOCUMENTS,
  FT.DECISIONS_CORE,
]

export const FEATURES_STARTER = [
  FT.ANNOUNCEMENTS,
  FT.SURVEYS,
  FT.FAQS,
  FT.DECISIONS_TICKETS,
  FT.INFORMATION_FULL,
  FT.DECISIONS_DOCUMENTS,
  FT.DECISIONS_FINANCIALS,
]

// TODO: sort these post production rollout (now it's easy to compare)
export const FEATURES_EXPERT = [
  FT.EMAIL_FORWARDING_CLIENT,
  FT.DOCUMENT_CATEGORIES,
  FT.AFTERCARE,
  FT.AFTERCARE_CUSTOMER_ISSUE_CREATION,
  FT.FINANCIALS,
  FT.PROJECT_PARTNERS,
  FT.INSPIRATIONS,
  FT.DECISIONS_PARTNERS,
  FT.DECISIONS_REMINDERS,
  FT.PROPOSAL_TEMPLATES,
  FT.MILESTONES,
  FT.CONVERSATION_MENTIONS,
  FT.CUSTOMERS,
  FT.SALES,
  FT.TODOS,
]

export const FEATURES_ENTERPRISE = [
  FT.DECISIONS_FINANCIALS_EXTENDED,
  FT.EMAIL_FORWARDING_PROJECT,
  FT.PROJECT_SUBDOMAINS,
  FT.AFTERCARE_CUSTOMER_FEEDBACK,
  FT.PROPOSAL_CHOICE_LISTS,
  FT.PROPOSAL_SUBCHOICES,
  FT.TEMPLATE_PROJECTS,
  FT.TICKET_CATEGORIES,
]

export const FEATURES_CUSTOM = [
  FT.EMPLOYEE_CUSTOM_THEME,
  FT.EMPLOYEE_IMPORT_UNITS,
  FT.EMPLOYEE_IMPORT_SPACES,
  FT.EMPLOYEE_IMPORT_CUSTOMERS,
]

export const FEATURES_DEV = [
  FT.SALES_PARTNERS,
]
