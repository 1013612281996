import { ref, readonly } from 'vue';

// Single global state
const isOpen = ref(false);
const isDestructive = ref(false);
const customMessage = ref('');
const customHeader = ref('');
const objectName = ref('');
let resolvePromise: ((value: boolean) => void) | null = null;

export function useGlobalConfirm() {
  const confirm = (options = { destructive: false, message: '', header: '', object_name: '' }): Promise<boolean> => {
    isOpen.value = true;
    isDestructive.value = options.destructive ?? false;
    customMessage.value = options.message ?? '';
    customHeader.value = options.header ?? '';
    objectName.value = options.object_name ?? '';
    return new Promise((resolve) => {
      resolvePromise = resolve;
    });
  };

  const handleConfirm = () => {
    isOpen.value = false;
    if (resolvePromise) {
      resolvePromise(true);
      resolvePromise = null;
    }
  };

  const handleCancel = () => {
    isOpen.value = false;
    if (resolvePromise) {
      resolvePromise(false);
      resolvePromise = null;
    }
  };

  return {
    isOpen: readonly(isOpen),
    isDestructive: readonly(isDestructive),
    customMessage: readonly(customMessage),
    customHeader: readonly(customHeader),
    objectName: readonly(objectName),
    confirm,
    handleConfirm,
    handleCancel
  };
} 