<template>
  <div v-if="initialized" :style="[theme_loaded ? {} : { 'display': 'none' }]">
    <AccessRevokedPage v-if="block_emp" />
    <div v-else>
      <Banner />
      <router-view/>
    </div>
  </div>
  <b-loading v-else :model-value="true" />
  <Toast />
  <GlobalConfirmModal />
</template>

<script>
import Banner from '@/app/shared_components/Banner.vue';
import AccessRevokedPage from '@/app/_employees/pages/AccessRevokedPage.vue';
import LogRocket from 'logrocket';
import swal from 'sweetalert';
import {
  GRANT_RECORDING_DURATION,
  PROFILE_TYPES,
  Tenant,
  TENANT_PICTURE_FAVICON_URL,
} from '@/app/data/model_constants';
import {
  ROUTE_COMMON,
  PROFILE_TO_ROUTES,
  PROFILE_TO_START_ROUTE,
  ROUTES_SUPERVISORS,
  ROUTES_USERS, ROUTES_EMPLOYEES,
} from '@/app/data/route_constants';
import * as Sentry from '@sentry/vue';
import {
  LOGIN_PAGE_NOTIFICATIONS,
  VITE_ENV_DEVELOPMENT,
  VITE_ENV_PRODUCTION,
  VITE_ENV_REVIEW,
} from '@/app/data/general_constants';
import { THEME, THEMES } from '@/app/data/themes_constants';
import { mapActions, mapGetters } from 'vuex';
import { differenceInSeconds } from 'date-fns';
import { ZIGGU_PARENT_URL, zigguProfileTenantURL } from '@/app/util/urls';
import { useMqService } from '@/plugins/mq';
import { getCurrentInstance } from 'vue'

export default {
  name: 'App',

  components: {
    AccessRevokedPage,
    Banner,
  },

  setup() {
    const mq = useMqService();
    return { mq };
  },

  data() {
    return {
      initialized: false,
      is_recording: false,
      theme_loaded: false,
    };
  },

  computed: {
    ...mapGetters([
      'billings',
      'current_is_impersonation',
      'current_profile',
      'current_profile_true',
      'current_client',
      'current_tenant',
    ]),

    authenticated() { return this.$auth.is_authenticated; },
    is_parent_domain() { return ZIGGU_PARENT_URL === window.location.origin; },

    billing_client() {
      if (!this.current_client) return {};

      return _.find(this.billings, ['attributes.client_id', this.current_client.id]);
    },

    block_emp() {
      return _.get(this.current_profile, 'type') === PROFILE_TYPES.EMPLOYEE
        && _.get(this.current_client, 'attributes.revoke_access_emp');
    },

    theme_name() {
      return _.get(this.current_tenant, 'attributes.theme_name', THEME.DEFAULT);
    },
  },

  watch: {
    authenticated: {
      async handler() {
        // profile handlers
        this.emitter.$on('initFavicon', this.initFavicon);
        this.emitter.$on('initIntercom', this.initIntercom);
        this.emitter.$on('initRecording', this.initRecording);
        this.emitter.$on('initTitle', this.initTitle);
        this.emitter.$on('logout', this.logout);

        // add impersonation meta attributes in localStorage, if present in URL
        const params = (new URL(document.location)).searchParams;
        const profile_type = params.get('_profile_type');
        const profile_id = params.get('_profile_id');
        if (profile_type && profile_id) {
          localStorage.setItem('_profile_type', profile_type);
          localStorage.setItem('_profile_id', profile_id);
        }

        // api error handlers
        this.emitter.$on('errors:401', this.handleErrorUnauthorized);
        this.emitter.$on('errors:403', this.handleErrorForbidden);
        this.emitter.$on('errors:404', this.handleErrorNotFound);
        this.emitter.$on('errors:422', this.handleErrorUnprocessable)
        this.emitter.$on('errors:500', this.handleErrorInternalServer);
        this.emitter.$on('errors:418', this.handleErrorForbiddenImpersonation)

        await this.initUser();

        this.emitter.$on('stopImpersonate', this.stopImpersonate);
      },
    },

    $route: {
      immediate: true,
      async handler() {
        if (this.initialized) this.initTitle();
        if (this.is_recording) this.initRecording();
      },
    },
  },

  created() {
    // used only once to get the buefy modal context issue working.
    // would not recommend to use this another time
    const app = getCurrentInstance();
    app.appContext.app.config.globalProperties.mq = this.mq;
  },

  methods: {
    ...mapActions([
      'UPDATE_EMAIL_ADDRESS_RESET',
      'FETCH_FEATURE_TOGGLES',
      'FETCH_USER_PROFILE',
      'LOGOUT_USER',
      'SET_CURRENT_HELPCENTER_URL',
    ]),

    async logout() {
      const impersonation = localStorage.getItem('_profile_type') !== null
        && localStorage.getItem('_profile_id') !== null;
      // logout trigger during impersonation stops impersonation
      if (impersonation) {
        await swal(this.$t('session.logout_impersonate.title'),
          this.$t('session.logout_impersonate.text'), 'warning');
        await this.stopImpersonate();
      } else {
        let url;
        // both no profile (TLD) and partner profile redirect back to TLD
        if (!this.current_profile || this.current_profile.type === PROFILE_TYPES.PARTNER) {
          url = ZIGGU_PARENT_URL;
        // other profiles redirect back to subdomain URL
        } else {
          url = zigguProfileTenantURL(this.current_profile, this.current_tenant);
        }
        // retrieve and append any (present) ziggu parameters to pass onto auth0 login page
        const ziggu_email_address = _.get(this.current_profile_true, 'attributes.email_address', '');
        const ziggu_notification = LOGIN_PAGE_NOTIFICATIONS.SUCCESS_LOGOUT;
        url = new URL(url);
        url.searchParams.append('ziggu_email_address', ziggu_email_address);
        url.searchParams.append('ziggu_notification', ziggu_notification);
        const returnTo = url.toString();
        await this.LOGOUT_USER({});
        window.Intercom('shutdown');
        this.$auth.logout({ returnTo });
      }
    },

    async handleErrorUnauthorized() {
      // illegal route navigation to other profile type routes
      if (this.current_profile && this.$route && this.$route.name
        && !this.$route.name.startsWith(this.current_profile.type.toLowerCase())) {
        await this.initUser();
      // unauthorized error after init: profile does not exist for given tenant or is not invited/active
      //  redirect back to parent domain name
      } else {
        // remove possible impersonation meta params, to prevent endless loop
        localStorage.removeItem('_profile_id');
        localStorage.removeItem('_profile_type');
        // parent domain: no user exists for this auth0 user, force logout
        if (this.is_parent_domain) {
          const url = new URL(window.location.origin);
          url.searchParams.append('ziggu_notification', LOGIN_PAGE_NOTIFICATIONS.ERROR_NO_ACCESS);
          const returnTo = url.toString();
          this.$auth.logout({ returnTo });
        // subdomain: no profile for user on this subdomain, redirect to parent domain
        } else {
          await swal(this.$t('session.noaccess.title', { hostname: window.location.hostname }),
            this.$t('session.noaccess.text'), 'warning');
          window.location.href = window.location.origin.replace(/^https:\/\/[^.]+\./g, 'https://');
        }
      }
    },

    handleErrorForbidden() {
      this.toast_action_failed();
    },

    handleErrorForbiddenImpersonation() {
      const message = this.$t('toast.action.impersonate_unauthorized');
      this.toast_action_failed(message);
    },

    handleErrorInternalServer() {
      this.toast_action_failed();
    },

    handleErrorNotFound() {
      // app needs to be initialized to render 404-page
      if (this.initialized) {
        this.$router.push({ name: ROUTE_COMMON.ERROR_NOT_FOUND });
      // app not initialized (FETCH_USER_PROFILE call likely gave a 404), redirect back to root of TLD
      } else {
        // remove possible impersonation meta params, to prevent endless loop
        localStorage.removeItem('_profile_id');
        localStorage.removeItem('_profile_type');
        window.location.href = this.is_parent_domain
          ? window.location.origin
          : window.location.origin.replace(/^https:\/\/[^.]+\./g, 'https://');
      }
    },

    handleErrorUnprocessable(error) {
      const message = this.$t('toast.action.unprocessable', { error })
      this.toast_action_failed(message);
    },

    async initUser() {
      this.initialized = false;
      await this.FETCH_USER_PROFILE({});
      // domain: (only) user is defined
      if (this.is_parent_domain) {
        await this.initTheme(THEME.DEFAULT);
        this.initialized = true;
        this.$router.push({ name: ROUTES_USERS.DASHBOARD });
      // subdomain: user, profile and profile_true are defined
      } else {
        await this.FETCH_FEATURE_TOGGLES({});
        await this.initTheme(this.theme_name);
        this.initSentry();
        await this.initStore();
        const { language } = this.current_profile.attributes;
        this.$i18n.locale = language;

        this.SET_CURRENT_HELPCENTER_URL({ hostname: window.location.hostname });

        // verify match between window and profile subdomain, else unauthorized notice
        const subdomain_window = window.location.host.split('.')[0];
        const subdomain_tenant = _.get(this.current_tenant, 'attributes.subdomain');
        const subdomain_profile = _.get(this.current_profile, 'attributes.subdomain');
        if (subdomain_window !== subdomain_tenant && subdomain_window !== subdomain_profile) {
          this.handleErrorUnauthorized();
        }
        this.initialized = true;

        const { email_address_reset_token } = this.$route.query;
        if (email_address_reset_token) this.confirmEmailChange(email_address_reset_token);

        const profile_type = this.current_profile.type;
        const profile_routes = PROFILE_TO_ROUTES[profile_type];
        if (this.$route.name
            && (!profile_routes.includes(this.$route.name) || this.$route.name.toLowerCase().includes('root'))
            && ROUTE_COMMON.NOTIFICATIONS_REDIRECT !== this.$route.name) {
          const route_start = PROFILE_TO_START_ROUTE[profile_type];
          const params = { client_id: this.current_profile.attributes.client_id };
          await this.$router.push({ name: route_start, params });
        }
      }
    },

    async confirmEmailChange(email_address_reset_token) {
      if (!email_address_reset_token) return;
      try {
        await this.UPDATE_EMAIL_ADDRESS_RESET({ email_address_reset_token });
        this.emitter.$emit('banner', { message: this.$t('profile.labels.updateEmailSuccessful'), color: 'success' });
        const query = { ...this.$route.query };
        delete query.email_address_reset_token;
        this.$router.replace(this.$route.path, { query });
      } catch {
        this.emitter.$emit('banner', { message: this.$t('profile.labels.updateEmailFailed'), color: 'danger' });
        throw new Error('user failed to update email');
      }
    },

    async initRecording() {
      if (this.is_recording) {
        const grant_recording_at = new Date(this.current_profile_true.attributes.grant_recording_at);
        const recording_at = new Date();
        const duration = differenceInSeconds(recording_at, grant_recording_at);
        const stop_recording = duration >= GRANT_RECORDING_DURATION;
        // hard refresh needed, as LogRocket SDK does not allow to stop recording (detrimental to their business model)
        if (stop_recording) {
          window.location.reload(true);
        }
      } else {
        const start_recording = !import.meta.env.IS_LOCAL
          && !this.current_is_impersonation
          && this.current_profile_true.attributes.grant_recording;
        // only capture in deployed mode while not impersonating, and only when explicitly approved by profile (!)
        if (start_recording) {
          LogRocket.identify(`${this.current_profile_true.type}-${this.current_profile_true.id}`, {
            name: this.current_profile.name,
          });
          LogRocket.init(import.meta.env.VITE_LOGROCKET_ID, {
            network: {
              requestSanitizer: (request) => {
                request.headers.authorization = null; /* eslint-disable-line no-param-reassign */
                return request;
              },
            },
          });
          this.is_recording = true;
          // add LogRocket session recording URL to every Sentry exception report
          LogRocket.getSessionURL((session_url) => {
            Sentry.configureScope((scope) => {
              scope.setExtra('logrocket_session_url', session_url);
            });
          });
        }
      }
    },

    async initIntercom() {
      // disable for test clients and for actual customers
      if (_.get(this.current_client, 'attributes.status') === Tenant.STATUS_TEST
          || this.current_profile_true.type === PROFILE_TYPES.CUSTOMER) return;

      // see https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
      let options = {};
      // development environment fallbacks to dummy 'Demo User', regardless of profile
      if (import.meta.env.VITE_ENV === VITE_ENV_DEVELOPMENT) {
        options = {
          user_id: 'demo_user_id',
          user_hash: '97a94ce0babc3f5e78aa530c9f481b54b07dd658da28be58560a7218bfcd1f87',
          name: 'Demo User',
        };
      } else {
        options = {
          user_id: this.current_profile_true.attributes.auth0_user_id,
          user_hash: this.current_profile_true.attributes.auth0_user_id_hmac,
          name: this.current_profile_true.attributes.name,
          email: this.current_profile_true.attributes.email_address,
          first_name: this.current_profile_true.attributes.first_name,
          last_name: this.current_profile_true.attributes.last_name,
          language: this.current_profile_true.attributes.language,
          impersonation_url: `${window.location.origin}/?`
              + `_profile_type=${this.current_profile_true.type}&`
              + `_profile_id=${this.current_profile_true.id}`,
          supervisor_url: 'https://supervisor.ziggu.app'
                          + `/supervisor/users/${this.current_profile_true.attributes.user_id}`
                          + `/profiles/${this.current_profile_true.type}/${this.current_profile_true.id}/info`,
          logrocket_url: `https://app.logrocket.com/${import.meta.env.VITE_LOGROCKET_ID}/`
            + `sessions?u=${this.current_profile_true.type}-${this.current_profile_true.id}`,
          package: _.get(this.current_client, 'attributes.package'),
          phone_mobile: _.get(this.current_profile_true, 'attributes.phone_mobile'),
          phone_landline: _.get(this.current_profile_true, 'attributes.phone_landline'),
          profile_type: this.current_profile_true.type,
          client_id: _.get(this.current_client, 'id'),
          subdomain: _.get(this.current_profile, 'attributes.subdomain'),
          arr: _.get(this.billing_client, 'attributes.arr'),
          avatar: { type: 'avatar', image_url: this.current_profile_true.attributes.avatar_url },
          profiles_count: this.current_profile_true.attributes.profiles_count,
          horizontal_nav_enabled: this.$flipper.enabled(this.$FT.HORIZONTAL_NAV)
        };
        // add company data for employee and partner profiles
        if (this.current_profile_true.type === PROFILE_TYPES.EMPLOYEE) {
          // employee profile's 'company' represents client's specifics
          options.company = {
            company_id: this.current_client.attributes.subdomain,
            company_status: this.current_client.attributes.status,
            created_at: Math.floor(Date.parse(this.current_client.attributes.created_at) / 1000),
            name: this.current_client.attributes.name,
          };
          options.role = this.current_profile_true.attributes.role;
          options.profession = this.current_profile_true.attributes.profession;
        } else if (this.current_profile_true.type === PROFILE_TYPES.PARTNER) {
          // partner profile's 'company' represents actual partner's company (with email address domain as unique id)
          const company_id = this.current_profile_true.attributes.email_address.split('@')[1];
          options.company = {
            company_id,
            company_status: this.current_client.attributes.status,
            created_at: Math.floor(Date.parse(this.current_client.attributes.created_at) / 1000),
            name: company_id,
          };
        }
      }
      // hide launcher when impersonating customers to avoid any confusion
      if (this.current_profile.type === PROFILE_TYPES.CUSTOMER) {
        options.hide_default_launcher = true;
      }

      window.Intercom('boot', {
        app_id: 'kqlc8dey',
        ...options,
      });
    },

    initSentry() {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.current_profile_true.attributes.user_id,
          profile_name: this.current_profile.attributes.name,
          profile_true_name: this.current_profile_true.attributes.name,
        });
        const { client_id } = this.current_profile.attributes;
        if (client_id) scope.setTag('client_id', client_id);
        scope.setTag('profile_tid', `${this.current_profile.type}#${this.current_profile.id}`);
        scope.setTag('profile_true_tid', `${this.current_profile_true.type}#${this.current_profile_true.id}`);
        if (import.meta.env.VITE_ENV === VITE_ENV_REVIEW) {
          scope.setTag('pull_request_id', _.parseInt(import.meta.env.VITE_PULL_REQUEST_ID));
        }
      });
    },

    async initStore() {
      // dynamic import of static pathnames
      const profile_store = await (async () => {
        switch (this.current_profile.type) {
        case PROFILE_TYPES.CUSTOMER:
          return import('@/store/customers/index.js');
        case PROFILE_TYPES.EMPLOYEE:
          return import('@/store/employees/index.js');
        case PROFILE_TYPES.PARTNER:
          return import('@/store/partners/index.js');
        case PROFILE_TYPES.SUPERVISOR:
          return import('@/store/supervisors/index.js');
        default:
          return null;
        }
      })();
      const module_name = `${this.current_profile.type.toLowerCase()}s`;
      await this.$store.registerModule(module_name, profile_store.default);
      this.$store.dispatch('ASSIGN_CURRENT_PROFILE', { profile: this.current_profile });
    },

    async initTheme(theme = THEME.DEFAULT, { done = () => {} } = {}) {
      // fallback to default theme
      if (!THEMES.has(theme) || (this.$flipper.enabled(this.$FT.HORIZONTAL_NAV) && this.current_profile.type === PROFILE_TYPES.EMPLOYEE && !this.$flipper.enabled(this.$FT.EMPLOYEE_CUSTOM_THEME))) {
        // eslint-disable-next-line no-param-reassign
        theme = THEME.DEFAULT;
      }
      // cache bust stylesheets via append of release version query string
      const hrefPath = `/static/css/themes/${theme}/application.css?v=${import.meta.env.RELEASE_VERSION}`;
      const link_old = document.getElementById('ziggu-css');
      // remove old different theme if loaded (impersonation)
      if (link_old && !link_old.href.endsWith(hrefPath)) {
        link_old.remove();
      }
      // init new theme if not already loaded
      if (!link_old || !link_old.href.endsWith(hrefPath)) {
        const link_new = document.createElement('link');
        link_new.rel = 'stylesheet';
        link_new.id = 'ziggu-css';
        link_new.onload = () => {
          this.theme_loaded = true;
          done();
        };
        link_new.media = 'all';
        link_new.href = hrefPath;
        document.head.appendChild(link_new);
      }
    },

    async initFavicon(picture_favicon_url) {
      // fallback to default favicon if none given or not in production env (used as environment indicator for safety)
      let value = picture_favicon_url;
      if (!value || import.meta.env.VITE_ENV !== VITE_ENV_PRODUCTION) value = TENANT_PICTURE_FAVICON_URL;
      const linkNew = document.createElement('link');
      const linkOld = document.getElementById('dynamic-favicon');
      linkNew.id = 'dynamic-favicon';
      linkNew.rel = 'shortcut icon';
      linkNew.href = value;
      if (linkOld) document.head.removeChild(linkOld);
      document.head.appendChild(linkNew);
    },

    async initTitle() {
      if (this.$route.name.includes('supervisor')) {
        document.title = 'Supervisor';
        return;
      }
      let title = '';
      const title_old = document.title;
      // append notifications counter from old title
      if (title_old.startsWith('(')) {
        const notifications_counter = title_old.split(' ')[0];
        title += `${notifications_counter} `;
      }
      // append route name
      const route_name_i18n = this.$t(`window.${this.$route.name}`);
      if (!route_name_i18n.endsWith(this.$route.name)) {
        title += ` ${route_name_i18n}`;
      }
      // append current_tenant name
      if (this.current_tenant) {
        title += ` - ${this.current_tenant.attributes.name}`;
      } else if (this.current_profile && this.current_profile.type === PROFILE_TYPES.SUPERVISOR) {
        title += 'Ziggu';
      }
      document.title = title;
    },

    async stopImpersonate() {
      // remove impersonation meta attributes in localStorage
      localStorage.removeItem('_profile_id');
      localStorage.removeItem('_profile_type');

      const { subdomain } = this.current_profile_true.attributes;
      const origin_tenant = `${window.location.origin.replace(/^https:\/\/[^.]*\./, `https://${subdomain}.`)}`;
      let route_path;
      // impersonator is supervisor
      if (this.current_profile_true.type === PROFILE_TYPES.SUPERVISOR) {
        route_path = this.$router.resolve({
          name: ROUTES_SUPERVISORS.USER_PROFILE_INFO,
          params: {
            user_id: this.current_profile.attributes.user_id,
            profile_id: this.current_profile.id,
            profile_type: this.current_profile.type,
          },
        }).href;
      // impersonator is employee
      } else {
        route_path = this.$router.resolve({
          name: ROUTES_EMPLOYEES.CLIENT_CONTACTS_TYPE,
          params: {
            client_id: this.current_profile.attributes.client_id,
            profile_id: this.current_profile.id,
            profile_type: this.current_profile.type,
          },
        }).href;
      }
      // Allow other components to handle the event before redirect
      _.defer(() => {
        window.location.href = [origin_tenant, route_path].join('');
      });
    },
  },
};
</script>
