<template>
  <div
    class="button has-text-weight-medium"
    :class="[is_outlined ? 'is-outlined' : '', is_light ? 'has-text-grey' : 'is-danger']"
    :disabled="(is_disabled || null)"
    @click="submit">
    <div v-if="!is_loading" class="icon">
      <FontAwesomeIcon icon="trash-can"/>
    </div>
    <div v-if="is_loading" class="icon">
      <FontAwesomeIcon icon="spinner" pulse/>
    </div>
    <div v-if="$slots.default">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import { useGlobalConfirm } from '@/composables/useGlobalConfirm';

const { confirm } = useGlobalConfirm();

const props = defineProps({
  is_outlined: { type: Boolean, default: false },
  is_light: { type: Boolean, default: false },
  is_disabled: { type: Boolean, default: false },
  is_loading: { type: Boolean, default: false },
  requires_confirmation: { type: Boolean, default: false },
});

const emit = defineEmits(['onClick']);

async function submit() {
  if (props.requires_confirmation) {
    const confirmed = await confirm({ destructive: true });
    if (!confirmed) return;
  }
  if (props.is_disabled) return;
  emit('onClick');
}
</script>
