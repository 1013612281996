<template>
  <ConfirmDialog />
</template>

<script setup>
import { useConfirm } from 'primevue/useconfirm';
import { watch, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useGlobalConfirm } from '@/composables/useGlobalConfirm';

const { t } = useI18n();
const confirm = useConfirm();
const {
  isOpen,
  isDestructive,
  objectName,
  customMessage,
  customHeader,
  handleConfirm,
  handleCancel
} = useGlobalConfirm();

// Watch for changes to the modal state
watch(isOpen, (newVal) => {
  if (!newVal) {
    confirm.close();
    return;
  }

  confirm.require({
    message: customMessage.value
      || (isDestructive.value
        ? t('modal.labels.areYouSureDelete', {
          object: objectName.value || t('modal.labels.this')
        })
        : ''),
    header: customHeader.value
      || (isDestructive.value
        ? t('modal.labels.dangerZone')
        : t('modal.labels.areYouSure')),
    rejectProps: {
      label: t('modal.actions.cancel'),
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: isDestructive.value
        ? t('modal.actions.remove')
        : t('modal.actions.submit'),
      severity: isDestructive.value ? 'danger' : 'primary'
    },
    accept: () => {
      handleConfirm();
    },
    reject: () => {
      handleCancel();
    },
    onHide: () => {
      handleCancel();
    },
  });
});

// Make sure to clean up on unmount
onUnmounted(() => {
  confirm.close();
});
</script>
