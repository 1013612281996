<template>
  <Modal
    data-test="project-modal"
    :has_parent="false"
    :is_small="false"
    :prevent_enter_shortcut="true"
    @actionCancel="$parent.$parent.close"
    @actionPrimary="onSubmit"
    @actionSecondary="onBack">
    <template #title>
      {{ $t(`project.modals.add_project.step_${current_step}.title`) }}
    </template>
    <template v-if="$te(`project.modals.add_project.step_${current_step}.subtitle`)" #subtitle>
      {{ $t(`project.modals.add_project.step_${current_step}.subtitle`) }}
    </template>
    <template v-if="current_step === 1" #content>
      <b-field :label="`${$t('project.labels.projectName')}`">
        <div class="field w-auto md:w-4/6 lg:w-3/6">
          <input
            v-model.trim="v$.project.attributes.name.$model"
            class="input"
            :class="{ 'is-danger': v$.project.attributes.name.$error }"
            data-test="questionlist-name-input"
            type="text">
          <div v-if="v$.project.attributes.name.$error">
            <p v-if="v$.project.attributes.name.required.$invalid" class="help is-danger">
              {{ $t('project.validation.needName') }}
            </p>
            <p v-if="v$.project.attributes.name.uniqueName.$invalid" class="help is-danger">
              {{ $t('validation.nameAlreadyTaken') }}
            </p>
          </div>
        </div>
      </b-field>
      <b-switch
        v-model="project.attributes.skip_customer"
        class="mb-6"
        @change="toggleTUP">
        {{ $t('project.labels.isTemplateProject') }}
      </b-switch>
    </template>
    <template v-if="current_step === 2" #content>
      <div class="columns">
        <div class="column is-offset-1 is-5 text-center">
          <div :class="[{ selected: project.attributes.skip_unit }, 'card', 'cursor-pointer', 'p-4', 'text-center', 'mb-3']" @click="initSUP">
            <p class="card-header-title justify-center">
              {{ $t('project.labels.singleUnit') }}
            </p>
          </div>
        </div>
        <div class="column is-5 text-center">
          <div :class="[{ selected: !project.attributes.skip_unit }, 'card', 'cursor-pointer', 'p-4', 'text-center', 'mb-3']" @click="initMUP">
            <p class="card-header-title justify-center">
              {{ $t('project.labels.multiUnits') }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="current_step === 3" #content>
      <section class="section mb-4" style="margin-top: -1rem;">
        <div class="columns">
          <div
            v-for="(level_option) in level_options"
            :key="level_option.key"
            class="column is-one-fifth ">
            <div
              class="box outline outline-slate-300 h-64 relative w-full"
              :class="{ 'has-background-white-ter': level_option.disabled, 'cursor-pointer': !level_option.disabled }"
              @click="toggleAttribute(level_option.attribute)">
              <div>
                <div class="title is-5">
                  {{ $truncate(level_option.label, 16) }}
                </div>
              </div>
              <p class="mt-2">
                <FontAwesomeIcon class="mr-2 has-text-grey" :icon="level_option.icon"/>
                {{ level_option.explainer }}
              </p>
              <div
                v-if="!project.attributes[level_option.attribute] && !level_option.disabled"
                class="tag is-success is-rounded absolute bottom-4">
                <FontAwesomeIcon
                  class="mr-2"
                  :icon="['fas', 'check']"/>
                {{ $t('project.labels.selected') }}
              </div>
              <div
                v-else-if="level_option.disabled"
                class="tag is-dark is-rounded absolute bottom-4">
                {{ $t('project.labels.included') }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <template v-if="current_step === 4" #content>
      <div v-if="$flipper.enabled($FT.TEMPLATE_PROJECTS) && !project.attributes.skip_customer" class="mb-6"
           style="height: 250px;">
        <SelectProject
          :active="project_template"
          :label="$t('project.modals.add_project.step_4.placeholder')"
          :projects_props="projects_template"
          @changeSelect="pt => project_template = pt"/>
        <section v-if="project_template" class="ml-5 mt-5">
          <b-field>
            <b-checkbox
              v-model="features.milestones">
              {{ $t('navbar.employee.project.milestones') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features.todo_lists"
            >
              {{ $t('navbar.employee.project.todos') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features.documents"
            >
              {{ $t('navbar.employee.project.documents') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-if="project.attributes.skip_unit"
                        v-model="features.decisions"
            >
              {{ $t('navbar.employee.project.decisions') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features.announcements"
            >
              {{ $t('navbar.employee.project.news') }}
            </b-checkbox>
          </b-field>
          <b-field v-if="project.attributes.skip_unit">
            <b-checkbox
              v-model="features.surveys"
            >
              {{ $t('navbar.employee.project.surveys') }}
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox
              v-model="features.question_lists"
            >
              {{ $t('navbar.employee.project.question_lists') }}
            </b-checkbox>
          </b-field>
        </section>
        <br/>
        <div v-if="project_template && !project.attributes.skip_unit" class="message is-primary">
          <div class="message-body is-flex">
            <FontAwesomeIcon class="has-text-primary mr-3" icon="circle-info" size="lg"/>
            <div>{{ $t('project.modals.add_project.step_4.info') }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="!$flipper.enabled($FT.TEMPLATE_PROJECTS)" class="message p-5 is-primary">
        <div class="mt-3" v-html="$t(`upsell.project_templates.description`)"/>
        <div class="is-italic mt-4">
          {{ $t('decision_type.labels.upsellCategoryTypes') }}
        </div>
      </div>
    </template>
    <template #ButtonPrimary>
      {{ button_primary_text }}
    </template>
    <template v-if="current_step > 1" #ButtonSecondary>
      {{ $t('modal.actions.goBack') }}
    </template>
  </Modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import Modal from '@/app/shared_components/modals/TheModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { ROUTES_EMPLOYEES } from '@/app/data/route_constants';
import SelectProject from '@/app/shared_components/selects/SelectProject.vue';
import { PROJECT_TYPES, Tenant } from '@/app/data/model_constants';

export default {
  name: 'ProjectModal',

  components: { SelectProject, Modal },

  setup() { return { v$: useVuelidate() }; },

  data() {
    return {
      current_step: 1,
      project: {
        attributes: {
          name: '',
          skip_phase: null,
          skip_lot: null,
          skip_building: null,
          skip_unit: null,
          skip_customer: null,
          status: 'enabled',
        },
      },
      project_template: null,
      features: {
        milestones: true,
        todo_lists: true,
        documents: true,
        decisions: true,
        surveys: true,
        question_lists: true,
        announcements: true
      },
      Tenant,
    };
  },

  validations: {
    project: {
      attributes: {
        name: {
          required,
          uniqueName(val) {
            return this.projects.length === 0
                || !_.find(this.projects, (project) => project.attributes.name.toLowerCase() === val.toLowerCase());
          },
        },
      },
    },
  },

  computed: {
    ...mapGetters(['current_client', 'projects']),

    projects_template() {
      // TODO: this needs pagination with backend filtering
      return _.chain(this.projects)
        .filter({ attributes: { skip_customer: true } })
        .orderBy(['attributes.name'], ['asc'])
        .value();
    },

    button_primary_text() {
      if (this.project.attributes.skip_customer || this.current_step === 4) {
        return this.$t('project.actions.submit');
      }

      return this.$t('project.actions.next');
    },

    level_options() {
      return {
        projects: {
          label: this.project.attributes.name,
          value: true,
          disabled: true,
          explainer: this.$t('project.labels.levelProjectExplainer')
        },
        phase: {
          attribute: 'skip_phase',
          label: this.$t('levels.phase'),
          value: !this.project.attributes.skip_phase,
          explainer: this.$t('project.labels.levelPhaseExplainer'),
          icon: 'calendar-days'
        },
        lot: {
          attribute: 'skip_lot',
          label: this.$t('levels.lot'),
          value: !this.project.attributes.skip_lot,
          explainer: this.$t('project.labels.levelPlotExplainer'),
          icon: 'chart-tree-map'
        },
        building: {
          attribute: 'skip_building',
          label: this.$t('levels.building'),
          value: !this.project.attributes.skip_building,
          explainer: this.$t('project.labels.levelBuildingExplainer'),
          icon: 'building'
        },
        units: {
          label: this.$t('levels.unit'),
          value: true,
          disabled: true,
          explainer: this.$t('project.labels.levelUnitExplainer'),
        },
      };
    },

    project_type() {
      return this.current_client.attributes.project_type;
    }
  },

  created() {
    if (this.project_type === PROJECT_TYPES.MUP) {
      this.initMUP();
    } else {
      this.initSUP();
    }
  },

  methods: {
    ...mapActions([
      'CREATE_PROJECT',
      'DUPLICATE_PROJECT',
    ]),

    toggleAttribute(attribute) {
      this.project.attributes[attribute] = !this.project.attributes[attribute];
    },

    toggleTUP() {
      return this.project.attributes.skip_customer ? this.initTUP() : this.initSUP();
    },

    initSUP() {
      this.project.attributes.skip_phase = true;
      this.project.attributes.skip_lot = true;
      this.project.attributes.skip_building = true;
      this.project.attributes.skip_unit = true;
      this.project.attributes.skip_customer = false;
    },

    initTUP() {
      this.initSUP();
      this.project.attributes.skip_customer = true;
    },

    initMUP() {
      this.project.attributes.skip_phase = true;
      this.project.attributes.skip_lot = true;
      this.project.attributes.skip_building = true;
      this.project.attributes.skip_unit = false;
      this.project.attributes.skip_customer = false;
    },

    // eslint-disable-next-line consistent-return
    onSubmit() { /* eslint-disable consistent-return */
      this.v$.$touch();
      if (!this.v$.$invalid) {
        switch (this.current_step) {
        // define name and whether it's a template project
        case 1:
          // project template => single step, immediately create project
          if (this.project.attributes.skip_customer) return this.createProject();
          switch (this.project_type) {
          case PROJECT_TYPES.MUP:
            this.current_step = 3;
            return;
          case PROJECT_TYPES.SUP:
            if (this.$flipper.enabled(this.$FT.TEMPLATE_PROJECTS)) {
              this.current_step = 4;
              return;
            }
            return this.createProject();
          default:
            this.current_step = 2;
            return;
          }

        // decide on mup or sup project
        case 2:
          switch (this.project.attributes.skip_unit) {
          case true:
            this.current_step = 4;
            return;
          default:
            this.current_step = 3;
            return;
          }

        // mup project: define sub levels
        case 3:
          this.current_step = 4;
          return;

        // mup/sup project: copy from template (optional)
        case 4:
          return this.createProject();
        default:
        }
        throw new Error('Invalid step');
      }
    },

    onBack() {
      if (this.current_step === 4) {
        let decrement;
        if (!this.project.attributes.skip_unit) { // MUP project
          decrement = 1
        } else {
          decrement = this.project_type === PROJECT_TYPES.MUP_AND_SUP ? 2 : 3;
        }
        this.current_step -= decrement;
        return;
      } if (this.project_type === PROJECT_TYPES.MUP && this.current_step === 3) {
        this.current_step -= 2;
        return;
      }
      this.current_step -= 1
    },

    async createProject() {
      let project;
      try {
        project = await this.CREATE_PROJECT({ record: this.project })
        this.$confetti.start();
        setTimeout(() => this.$confetti.stop(), 5000);
      } catch (e) {
        if (e.response.status === 422) {
          const errors = e.response.data.errors
          const errorKey = Object.keys(errors)[0]
          const errorMessage = errors[errorKey][0]
          this.toast_fail(this.$t(`project.errors.${errorKey}.${errorMessage}`));
        } else {
          this.toast_action_failed()
        }
      }
      if (this.project_template) {
        await this.DUPLICATE_PROJECT({
          record: {
            id_template: this.project_template.id,
            id_duplicate: project.id,
            attributes: {
              features: this.features
            }
          }
        });
      }
      this.$parent.$parent.close();
      if (project) {
        const route_name = this.project.attributes.skip_customer
          ? ROUTES_EMPLOYEES.PROJECT_MILESTONES
          : ROUTES_EMPLOYEES.PROJECT_INFORMATION_LEVELS;
        this.$router.push({
          name: route_name,
          params: { project_id: project.id, level_type: project.type, level_id: project.id }
        })
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .card {
    border-radius: 0.5rem;
    &.selected {
      border: 1px solid #1e2445;
      color: #1e2445;
      background-color: #f8f9fc;

      .card-header-title {
        color: #1e2445;
      }
    }
  }

  .unit-explanation {
    color: #363636;
  }
</style>
